// CheckAnswerService.js
export function checkAnswer(question, user_answer) {
    return new Promise((resolve, reject) => {
      const socket = new WebSocket('wss://dev.chromecopilot.ai/ws/check_answer/');
  
      socket.onopen = () => {
        console.log('✅ WebSocket connected for answer check.');
        const message = {
          type: 'check_answer',
          payload: { question, user_answer }
        };
        socket.send(JSON.stringify(message));
      };
  
      socket.onmessage = (event) => {
        console.log('📩 Received message from check answer WS:', event.data);
        try {
          const data = JSON.parse(event.data);
          // Expect a message with type "answer_check" and an "answer_check" key containing a JSON string.
          if (data.type === 'answer_check' && data.answer_check) {
            // Parse the nested JSON string.
            const answerCheck = JSON.parse(data.answer_check);
            resolve(answerCheck);
            socket.close();
          } else if (data.type === 'error') {
            reject(data.message);
            socket.close();
          } else {
            reject('Unexpected message format.');
            socket.close();
          }
        } catch (error) {
          reject('Failed to parse response.');
          socket.close();
        }
      };
  
      socket.onerror = (error) => {
        console.error('❌ WebSocket error in checkAnswerService:', error);
        reject('WebSocket error.');
        socket.close();
      };
  
      socket.onclose = () => {
        console.log('🔴 WebSocket closed for answer check.');
      };
    });
  }
  