import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../helpers/AuthProvider';
import { useParams } from 'react-router-dom';
import './MultipleChoiceQuestion.css';
import gsap from 'gsap';

function MultipleChoiceQuestion({ questionData, difficulty, onAnswerSubmit = () => {}, isLastQuestion }) {
  const { token } = useAuth();
  const { id: classroomId } = useParams();
  const [selectedOption, setSelectedOption] = useState(questionData.userAnswer || null);
  const containerRef = useRef(null);

  useEffect(() => {
    setSelectedOption(questionData.userAnswer || null);
  }, [questionData]);

  useEffect(() => {
    if (containerRef.current) {
      gsap.fromTo(
        containerRef.current,
        { scale: 0.5, opacity: 0 },
        { scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
      );
    }
  }, []);

  const handleSelect = async (option) => {
    setSelectedOption(option);
    const isCorrect = option === questionData.answer;
    try {
      const payload = {
        questions: { ...questionData, userAnswer: option, correct: isCorrect }
      };
      if (isLastQuestion) {
        payload.end_time = new Date().toISOString();
      }
      const response = await axios.patch(
        `/api/quizzes/?classroom_id=${classroomId}`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("✅ Answer saved successfully:", response.data);
    } catch (error) {
      console.error("❌ Error saving answer:", error.response?.data || error.message);
    }
    if (typeof onAnswerSubmit === "function") {
      onAnswerSubmit(isCorrect, questionData.explanation);
    }
  };

  return (
    <div ref={containerRef} className={`multiple-choice-container ${difficulty.toLowerCase()}`}>
      <h3 className="question-text">{questionData.question}</h3>
      <div className="choices-container">
        {Object.entries(questionData.choices).map(([key, value]) => (
          <div
            key={key}
            className={`choice-option ${selectedOption === key ? 'selected' : ''} ${selectedOption ? (key === questionData.answer ? 'correct' : 'incorrect') : ''}`}
            onClick={() => handleSelect(key)}
          >
            <span className="choice-key">{key}.</span> {value}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MultipleChoiceQuestion;
