import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../helpers/AuthProvider';
import './ResultsPage.css';

function ResultsPage() {
  const { id: classroomId } = useParams();
  const { token } = useAuth();
  const [quizDetails, setQuizDetails] = useState(null);
  const [error, setError] = useState(null);
  // Track which tile (by index) is expanded
  const [expandedTiles, setExpandedTiles] = useState({});

  useEffect(() => {
    axios
      .get(`/api/quizzes/?classroom_id=${classroomId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data && response.data.questions) {
          setQuizDetails(response.data);
        } else {
          setError('No quiz details found.');
        }
      })
      .catch((error) => {
        console.error(
          'Error fetching quiz details:',
          error.response?.data || error.message
        );
        setError('Error fetching quiz details.');
      });
  }, [classroomId, token]);

  if (error) {
    return <p>{error}</p>;
  }

  if (
    !quizDetails ||
    !quizDetails.questions ||
    !quizDetails.start_time ||
    !quizDetails.end_time
  ) {
    return <p>Loading results...</p>;
  }

  const questions = quizDetails.questions;
  const totalQuestions = questions.length;
  const correctCount = questions.filter((q) => q.correct).length;

  // Calculate time difference in mm:ss format
  const start = new Date(quizDetails.start_time);
  const end = new Date(quizDetails.end_time);
  const diffMs = end - start;
  const diffSec = Math.floor(diffMs / 1000);
  const minutes = Math.floor(diffSec / 60);
  const seconds = diffSec % 60;
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;

  const passThreshold = 0.6;
  const hasPassed = correctCount / totalQuestions >= passThreshold;

  const toggleTile = (index) => {
    setExpandedTiles((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="quiz-results">
      {/* Extra Info column on the left */}
      <div className="quiz-results__extra-info">
        <div className="quiz-results__time">
          <p>{formattedTime}</p>
        </div>
        <div className="quiz-results__score">
          <p>{correctCount} / {totalQuestions}</p>
        </div>
        <div className="quiz-results__has-passed">
          <p>{hasPassed ? 'Passed' : 'Failed'}</p>
        </div>
      </div>
      {/* Tiles grid on the right */}
      <div className="quiz-results__tiles">
        {questions.map((q, idx) => (
          <div
            key={q.id || idx}
            className={`results-tile ${
              q.correct ? 'results-tile--correct' : 'results-tile--incorrect'
            } ${expandedTiles[idx] ? 'results-tile--expanded' : ''}`}
            onClick={() => toggleTile(idx)}
            title={q.question} /* Full text available on hover */
          >
            <div className="results-tile__number">{idx + 1}</div>
            {expandedTiles[idx] && (
              <div className="results-tile__details">
                <div className="results-tile__question">
                  <strong>Question:</strong> {q.question}
                </div>
                <div className="results-tile__user-answer">
                  <strong>Your answer:</strong> {q.userAnswer || 'No answer'}
                </div>
                <div className="results-tile__correct-answer">
                  <strong>Correct answer:</strong> {q.answer || 'N/A'}
                </div>
                {q.explanation && (
                  <div className="results-tile__explanation">
                    <strong>Explanation:</strong> {q.explanation}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ResultsPage;
