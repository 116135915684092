import React, { useState, useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './CreateTutor2LP.css';
import WaveSurfer from 'wavesurfer.js';

import sidebarImage from '../../../assets/landingPageImages/ai-chat.png';
import voiceImage from '../../../assets/landingPageImages/ai-voice-2.png';
import personalityImage from '../../../assets/landingPageImages/ai-personality.png';
import videoImage from '../../../assets/landingPageImages/ai-video.png';
import searchImage from '../../../assets/landingPageImages/ai-search.png';
import quickActionsImage from '../../../assets/landingPageImages/ai-quick-actions.png';

import copilotMascotImage from '../../../assets/landingPageImages/copilot-mascot.png';

import alloyAudio from '../../../assets/audio/TTS-test-alloy.mp3';
import echoAudio from'../../../assets/audio/TTS-test-echo.mp3';
import fableAudio from '../../../assets/audio/TTS-test-fable.mp3';
import onyxAudio from '../../../assets/audio/TTS-test-onyx.mp3';
import novaAudio from '../../../assets/audio/TTS-test-nova.mp3';
import shimmerAudio from '../../../assets/audio/TTS-test-shimmer.mp3';

gsap.registerPlugin(ScrollTrigger);

function CreateTutor2LP() {
    // REFs
    const isInitialLoadRef = useRef(true);

    const meetCopilotSectionRef = useRef(null);
    const meetCopilotTextRef = useRef(null);
    const meetCopilotSubheadingContainer = useRef(null);

    const subheadings = ['What am I', 'Personality', 'Voice', 'About you', 'Privacy'];
    const subheadingRefs = useRef([]);
    const slidingPillRef = useRef(null);

    const [isPillInView, setIsPillInView] = useState(false);
    const isProgrammaticScrollRef = useRef(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const activeIndexRef = useRef(activeIndex);

    const [carouselPadding, setCarouselPadding] = useState(0);
    const outerCarouselRef = useRef(null);
    const carouselRef = useRef(null);
    const [isScrolling, setIsScrolling] = useState(false);

    const tileRefs = useRef([]);  // hold refs for each tile

    const personalityTileRef = useRef(null);

    // Initial Animate in 'Meet your AI' when in view
    useEffect(() => {
        if (meetCopilotTextRef.current) {
            // Initial fade-in for title
            if (window.scrollY === 0) {
                gsap.fromTo(
                    meetCopilotTextRef.current,
                    { opacity: 0, scale: 0.8 },
                    {
                        duration: 1.8,
                        opacity: 1,
                        scale: 1,
                        ease: 'expo.inOut',
                    }
                );
            }
        }
    }, []);
    
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              setIsPillInView(entry.isIntersecting);
            });
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 0.75,
          }
        );
      
        if (meetCopilotSectionRef.current) {
          observer.observe(meetCopilotSectionRef.current);
        }
      
        return () => {
          if (meetCopilotSectionRef.current) {
            observer.unobserve(meetCopilotSectionRef.current);
          }
        };
    }, []);


    // Setup dynamic padding for carousel
    const calculatePadding = () => {
        if (carouselRef.current && tileRefs.current[0]) {
            const screenWidth = window.innerWidth;
            const contentWidth = tileRefs.current[0].offsetWidth;
            const paddingValue = (screenWidth - contentWidth) / 2;
            setCarouselPadding(paddingValue > 0 ? paddingValue : 0);
        }
    };

    useEffect(() => {
        calculatePadding(); 
        window.addEventListener('resize', calculatePadding);
        return () => window.removeEventListener('resize', calculatePadding);
    }, []);


    // Animate in the subheading pill container
    useEffect(() => {
        if (meetCopilotSubheadingContainer.current) {
            gsap.fromTo(
                meetCopilotSubheadingContainer.current,
                {
                    y: 50,
                    opacity: 0,
                    width: 0,
                },
                {
                    y: 0,
                    opacity: 1,
                    width: 'auto',
                    duration: 1,
                    ease: 'elastic.out(0.2, 0.2)',
                }
            );
        }
    }, []);

    useEffect(() => {
        if (meetCopilotSubheadingContainer.current) {
            const element = meetCopilotSubheadingContainer.current;
            gsap.killTweensOf(element);
        
            if (isPillInView) {
                // Build the tween configuration
                const tweenConfig = {
                    y: 0,
                    opacity: 1,
                    width: 'auto',
                    duration: 1,
                    ease: 'elastic.out(0.2, 0.2)',
                };
        
                // If this is the first time after load, update on every frame
                if (isInitialLoadRef.current) {
                    tweenConfig.onUpdate = updatePillPosition;
                    tweenConfig.onComplete = () => {
                        // Mark that the initial load is done
                        isInitialLoadRef.current = false;
                    };
                } else {
                    // For subsequent animations, only update on complete
                    tweenConfig.onComplete = updatePillPosition;
                }
        
                // Animate the element in from y:50, opacity:0, width:0
                gsap.fromTo(
                    element,
                    { y: 50, opacity: 0, width: 0 },
                    tweenConfig
                );
            } else {
                // Animate out (no change here)
                gsap.to(element, {
                    y: 50,
                    opacity: 0,
                    width: 0,
                    duration: 0.25,
                    ease: 'power2.in',
                });
            }
        }
    }, [isPillInView]);



    // Whenever activeIndex changes, move the pill indicator
    const updatePillPosition = useCallback(() => {
        if (subheadingRefs.current[activeIndex] && slidingPillRef.current) {
            const activeSubheading = subheadingRefs.current[activeIndex];
            const parentRect = activeSubheading.parentElement.getBoundingClientRect();
            const subheadingRect = activeSubheading.getBoundingClientRect();
        
            const left = subheadingRect.left - parentRect.left;
            const width = subheadingRect.width;

            gsap.to(slidingPillRef.current, {
                duration: 0.4,
                left,
                width,
                ease: 'power2.out',
            });
        }
    }, [activeIndex]);
  
    useEffect(() => {
        updatePillPosition();
    }, [activeIndex, updatePillPosition]);

    // Update the active index whenever it changes in state
    useEffect(() => {
        activeIndexRef.current = activeIndex;
    }, [activeIndex]);

    // On mount, set up a scroll listener for each content section so pill moves accordingly
    useEffect(() => {
        const container = outerCarouselRef.current;
        if (!container) return;
      
        const handleScroll = () => {
          if (isProgrammaticScrollRef.current) {
            // If we’re scrolling programmatically, ignore user scroll updates
            return;
          }
          // Otherwise, update activeIndex by position
          updateActiveIndexByScrollPosition();
        };
      
        container.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
          container.removeEventListener('scroll', handleScroll, { passive: true });
        };
    }, []);
      
    const updateActiveIndexByScrollPosition = () => {
        const container = outerCarouselRef.current;
        if (!container) return;
      
        // Container's bounding box in viewport coords
        const containerRect = container.getBoundingClientRect();
        const containerCenterX = containerRect.left + containerRect.width / 2;
      
        let closestIndex = 0;
        let minDistance = Infinity;
      
        tileRefs.current.forEach((tileEl, index) => {
            if (!tileEl) return;
        
            // Each tile's bounding box in viewport coords
            const tileRect = tileEl.getBoundingClientRect();
            const tileCenterX = tileRect.left + tileRect.width / 2;
        
            const distance = Math.abs(containerCenterX - tileCenterX);
            if (distance < minDistance) {
                minDistance = distance;
                closestIndex = index;
            }
        });
      
        if (closestIndex !== activeIndexRef.current) {
            setActiveIndex(closestIndex);
        }
    };

    function scrollToTile(tileEl) {
        // Mark that we’re doing a programmatic scroll
        isProgrammaticScrollRef.current = true;
        setIsScrolling(true);
      
        // Start the smooth scroll
        tileEl.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        });
      
        // Begin polling until scroll is finished
        checkIfScrollDone();
      
        function checkIfScrollDone() {
            // Read positions
            const container = outerCarouselRef.current;
            if (!container) return;
        
            const containerCenter = container.scrollLeft + container.offsetWidth / 2;
            const tileCenter = tileEl.offsetLeft + tileEl.offsetWidth / 2;
            const distance = Math.abs(containerCenter - tileCenter);
        
            // If within some small threshold, we consider it done
            if (distance < 1) {
                isProgrammaticScrollRef.current = false;
                setIsScrolling(false);
                return;
            }
            // Otherwise, keep checking on the next animation frame
            requestAnimationFrame(checkIfScrollDone);
        }
    }



    // Personality accordion
    // Map of personality sections to gradient backgrounds

    // possible flexibile bg = linear-gradient(199deg, rgb(28 166 190), rgb(48, 74, 133))

    const gradientBackgrounds = {
        'LP-personality-1': 'url(https://www.transparenttextures.com/patterns/broken-noise.png), linear-gradient(199deg, rgb(44 209 238), rgb(24 49 106))',

        'LP-personality-2': 'url(https://www.transparenttextures.com/patterns/broken-noise.png), linear-gradient(199deg, rgb(117 156 137), rgb(9 70 45))',

        'LP-personality-3': 'url(https://www.transparenttextures.com/patterns/broken-noise.png), linear-gradient(199deg, rgb(209 167 103), rgb(115 20 63))',

        'LP-personality-4': 'url(https://www.transparenttextures.com/patterns/broken-noise.png), linear-gradient(199deg, rgb(152 179 246), rgb(62 23 94))',
    };
  
    // State to keep track of the open section
    const [openPersonalitySection, setOpenPersonalitySection] = useState('LP-personality-1');

    // Refs for each personality section
    const personalityRefs = useRef({
        'LP-personality-1': null,
        'LP-personality-2': null,
        'LP-personality-3': null,
        'LP-personality-4': null,
    });

    // Ref for the gradient overlay
    const gradientOverlayRef = useRef(null);

    useEffect(() => {
        if (personalityTileRef.current) {
          const parentElement = personalityTileRef.current;
          parentElement.style.backgroundImage = gradientBackgrounds[openPersonalitySection];
        }
    }, []);

    const prevOpenPersonalitySectionRef = useRef(openPersonalitySection);

    useEffect(() => {
      if (prevOpenPersonalitySectionRef.current !== openPersonalitySection) {
        if (gradientOverlayRef.current && personalityTileRef.current) {
          const parentElement = personalityTileRef.current;
    
          // Parent element's background remains as the previous gradient
          const prevGradient = gradientBackgrounds[prevOpenPersonalitySectionRef.current];
          parentElement.style.backgroundImage = prevGradient;
    
          // Set the new gradient on the overlay
          const newGradient = gradientBackgrounds[openPersonalitySection];
          gradientOverlayRef.current.style.backgroundImage = newGradient;
    
          // Reset overlay opacity to 0
          gradientOverlayRef.current.style.opacity = 0;
    
          // Force reflow
          void gradientOverlayRef.current.offsetWidth;
    
          // Animate overlay opacity to 1
          gradientOverlayRef.current.style.opacity = 1;
    
          // After transition, update parent background and reset overlay opacity
          setTimeout(() => {
            if (gradientOverlayRef.current && parentElement) {
              parentElement.style.backgroundImage = newGradient;
              gradientOverlayRef.current.style.opacity = 0;
            }
          }, 500); // Duration matches the CSS transition time
    
          // Update the previous section reference
          prevOpenPersonalitySectionRef.current = openPersonalitySection;
        }
      }
    }, [openPersonalitySection]);

    const togglePersonalitySection = (section) => {
        if (openPersonalitySection === section) return; // Do nothing if the clicked section is already open

        // Remove 'open' class and reset styles from the previously open section
        const prevSection = openPersonalitySection;
        if (prevSection && personalityRefs.current[prevSection]) {
            const prevSectionEl = personalityRefs.current[prevSection];
            prevSectionEl.classList.remove('open');

            // Reset the height of the previous content to 0
            const prevContent = prevSectionEl.querySelector('.LP-personality-accordion-text');
            if (prevContent) {
                prevContent.style.height = '0px';
                prevContent.style.paddingTop = '0';
            }
        }

        // Add 'open' class and set styles for the clicked section
        if (personalityRefs.current[section]) {
            const currentSectionEl = personalityRefs.current[section];
            currentSectionEl.classList.add('open');

            // Expand the height of the current content to its scrollHeight
            const currentContent = currentSectionEl.querySelector('.LP-personality-accordion-text');
            if (currentContent) {
                const contentHeight = currentContent.scrollHeight;
                currentContent.style.height = `${contentHeight}px`;
                currentContent.style.paddingTop = '15px';
            }
        }

        // Update the state to the new open section
        setOpenPersonalitySection(section);
    };

    useEffect(() => {
        const initializeAccordion = () => {
          Object.keys(personalityRefs.current).forEach((sectionKey) => {
            const sectionEl = personalityRefs.current[sectionKey];
            if (sectionEl) {
              const contentEl = sectionEl.querySelector('.LP-personality-accordion-text');
      
              if (sectionKey === openPersonalitySection) {
                // Open the default section
                sectionEl.classList.add('open');
                if (contentEl) {
                  const contentHeight = contentEl.scrollHeight;
                  contentEl.style.height = `${contentHeight}px`;
                  contentEl.style.paddingTop = '15px';
                }
              } else {
                // Ensure other sections are closed
                sectionEl.classList.remove('open');
                if (contentEl) {
                  contentEl.style.height = '0px';
                  contentEl.style.paddingTop = '0';
                }
              }
            }
          });
        };
      
        if (document.fonts && document.fonts.ready) {
          document.fonts.ready.then(initializeAccordion);
        } else {
          // Fallback for browsers that don't support document.fonts
          window.addEventListener('load', initializeAccordion);
          return () => {
            window.removeEventListener('load', initializeAccordion);
          };
        }
    }, []);



    // Voice section
    const waveSurferRefs = useRef({});
    const [playingVoice, setPlayingVoice] = useState(null);  // Track the currently playing voice
    const [isPlaying, setIsPlaying] = useState({}); // Track the play/pause state of each voice

    const audioFiles = {
        alloy: alloyAudio,
        echo: echoAudio,
        fable: fableAudio,
        onyx: onyxAudio,
        nova: novaAudio,
        shimmer: shimmerAudio
    };
    
    useEffect(() => {
        const voices = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];
    
        voices.forEach((voice) => {
            const waveSurfer = WaveSurfer.create({
                container: `#LP-waveform-${voice}`,
                fillParent: true,
                height: '50',
                barHeight: '1.5',
                waveColor: '#f2f2f2',
                progressColor: '#ffffff80',
                cursorWidth: '0',
                barWidth: '2',
                barGap: '2',
                barRadius: '10'
            });
    
            // Load the corresponding audio file
            waveSurfer.load(audioFiles[voice]);
    
            // Save the waveSurfer instance in refs for later access
            waveSurferRefs.current[voice] = waveSurfer;

            // Event listener to reset the play icon after the audio ends
            waveSurfer.on('finish', () => {
                setIsPlaying(prevState => ({ ...prevState, [voice]: false }));
                setPlayingVoice(null); // Reset the currently playing voice
            });
        });
    
        return () => {
            voices.forEach((voice) => {
                if (waveSurferRefs.current[voice]) {
                    waveSurferRefs.current[voice].destroy();
                }
            });
        };
    }, []);

    const handlePlayPause = (voice) => {
        const waveSurfer = waveSurferRefs.current[voice];

        if (waveSurfer.isPlaying()) {
            waveSurfer.pause();
            setIsPlaying(prevState => ({ ...prevState, [voice]: false })); // Set paused state for this voice
            setPlayingVoice(null); // No currently playing voice
        } else {
            // Pause any currently playing voice
            if (playingVoice && playingVoice !== voice) {
                waveSurferRefs.current[playingVoice].pause();
                setIsPlaying(prevState => ({ ...prevState, [playingVoice]: false }));
            }

            // Play the selected voice
            waveSurfer.play();
            setIsPlaying(prevState => ({ ...prevState, [voice]: true })); // Set playing state for this voice
            setPlayingVoice(voice); // Set this voice as currently playing
        }
    };



    // About You section
    useEffect(() => {
        const questions = [
          '#question-1',
          '#question-6',
          '#question-12',
          '#question-15',
          '#question-2',
          '#question-7',
          '#question-11',
          '#question-18',
          '#question-5',
          '#question-8',
          '#question-14',
          '#question-19',
          '#question-4',
          '#question-9',
          '#question-13',
          '#question-16',
          '#question-3',
          '#question-10',
          '#question-17'
        ];

        const baseDelay = 0; // Base delay between each question's start
        const incrementDelay = 1.4; // Increment delay for each subsequent question
    
        questions.forEach((question, index) => {
          const duration = 30 + Math.random() * 1; 
          const delay = baseDelay + (incrementDelay * index); // Progressive delay based on index
          
          gsap.to(question, {
            x: '-50em', // Move it completely out of the container on the right
            duration: duration, 
            ease: 'linear',
            repeat: -1, // Infinite loop
            delay: delay,
            onRepeat: function () {
              // Reset position when animation restarts
              gsap.set(question, { x: '100%' });
            }
          });
        });
    }, []);

    return (
        <div className="LP-meet-copilot-container" ref={meetCopilotSectionRef}>
            <div className='LP-product-text-wrapper' ref={meetCopilotTextRef}>
                <p className="LP-product-title">Meet your AI.</p>
            </div>
            <div className='LP-pill-subheading-container' ref={meetCopilotSubheadingContainer}>
                <div className="LP-sliding-pill" ref={slidingPillRef}></div>

                {subheadings.map((subheading, index) => (
                    <div
                        key={index}
                        ref={(el) => (subheadingRefs.current[index] = el)}
                        className={`LP-pill-subheading-item ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => {
                            // 1) Move the pill (instantly) so user sees an immediate subheading highlight
                            setActiveIndex(index);

                            // 2) Actually scroll the carousel
                            const tileEl = tileRefs.current[index];
                            if (tileEl) {
                                scrollToTile(tileEl);
                            }
                        }}
                    >
                        <p className='LP-pill-subheading-text'>{subheading}</p>
                    </div>
                ))}
            </div>

            {/* Overlay to prevent scrolling */}
            {isScrolling && (
                <div 
                    className="LP-scroll-overlay"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 100,    // make sure it sits above the carousel content
                        background: 'transparent'  // invisible but blocks pointer events
                    }}
                />
            )}

            {/* The main "carousel" container */}
            <div 
                className="LP-meet-copilot-feature-container"
                ref={outerCarouselRef}
            >
                <div
                    className="LP-meet-AI-carousel" 
                    ref={carouselRef}
                    style={{ paddingLeft: `${carouselPadding}px`, paddingRight: `${carouselPadding}px` }}
                >

                    <div
                        className="LP-content-section"
                        ref={(el) => (tileRefs.current[0] = el)}
                    >
                        <div
                            className='LP-feature-tile LP-what-am-i-tile'
                        >
                            <i className="fa-solid fa-seedling LP-meet-ai-seedling"></i>
                            <p className="LP-meet-ai-title LP-what-am-i-tile-title">
                                <span>Your personal growth companion.</span> Integrated into your browser for a one-to-one tutor experience.
                            </p>
                            <img
                                className="LP-copilot-mascot-image" 
                                src={copilotMascotImage} 
                                alt="Copilot" 
                            />
                        </div>
                    </div>

                    {/* Content for "Personality" */}
                    <div
                        className="LP-content-section"
                        ref={(el) => (tileRefs.current[1] = el)}
                    >
                        <div
                            className='LP-feature-tile LP-personality-tile'
                            ref={personalityTileRef}
                        >
                            {/* Gradient Overlay */}
                            <div
                                className="LP-gradient-overlay"
                                ref={gradientOverlayRef}
                            >
                            </div>

                            <p className='LP-meet-ai-title LP-personality-title'>
                                <span>Craft your perfect ally.</span> Begin your adventure by choosing from four distinct personalities.
                            </p>

                            <i className="fa-solid fa-seedling LP-meet-ai-seedling"></i>
                            
                            {/* Accordion Content */}
                            <div className='LP-personality-accordion-container'>
                                <div
                                    className='LP-personality-section LP-personality-1-container'
                                    onClick={() => togglePersonalitySection('LP-personality-1')}
                                    ref={(el) => (personalityRefs.current['LP-personality-1'] = el)}
                                >
                                    <div className='LP-personality-accordion-title-container'>
                                        <p className='LP-personality-title'>
                                            <i className="bi bi-tsunami LP-personality-wave-icon"></i>
                                            Flexible
                                        </p>
                                        <div className='LP-personality-accordion-arrow'></div>
                                    </div>
                                    {/* Conditionally render the text */}
                                    <p className='LP-personality-accordion-text'>
                                        Adaptable, even-handed and easy-going. This personality becomes what it needs to be in the moment, moulding itself to your current problem or query.
                                    </p>
                                </div>
                                <div className='LP-seperator'></div>
                                <div
                                    className='LP-personality-section LP-personality-2-container'
                                    onClick={() => togglePersonalitySection('LP-personality-2')}
                                    ref={(el) => (personalityRefs.current['LP-personality-2'] = el)}
                                >
                                    <div className='LP-personality-accordion-title-container'>
                                        <p className='LP-personality-title'>
                                            <i className="fa-solid fa-mountain LP-personality-mountain-icon"></i>
                                            Stoic
                                        </p>
                                        <div className='LP-personality-accordion-arrow'></div>
                                    </div>
                                    {/* Conditionally render the text */}
                                    <p className='LP-personality-accordion-text'>
                                        Firm, professional, and pragmatic. This personality is characterised by its gravitas and sophisticated approach to learning and self improvement.
                                    </p>
                                </div>
                                <div className='LP-seperator'></div>
                                <div
                                    className='LP-personality-section LP-personality-3-container'
                                    onClick={() => togglePersonalitySection('LP-personality-3')}
                                    ref={(el) => (personalityRefs.current['LP-personality-3'] = el)}
                                >
                                    <div className='LP-personality-accordion-title-container'>
                                        <p className='LP-personality-title'>
                                            <i className="bi bi-fire LP-personality-fire-icon"></i>
                                            Intense
                                        </p>
                                        <div className='LP-personality-accordion-arrow'></div>
                                    </div>
                                    {/* Conditionally render the text */}
                                    <p className='LP-personality-accordion-text'>
                                        Passionate, focused, and ambitious. This personality has one thing on its mind: ensuring you become the best version of yourself. You'll have to keep up.
                                    </p>
                                </div>
                                <div className='LP-seperator'></div>
                                <div
                                    className='LP-personality-section LP-personality-4-container'
                                    onClick={() => togglePersonalitySection('LP-personality-4')}
                                    ref={(el) => (personalityRefs.current['LP-personality-4'] = el)}
                                >
                                    <div className='LP-personality-accordion-title-container'>
                                        <p className='LP-personality-title'>
                                            <i className="bi bi-tornado LP-personality-tornado-icon"></i>
                                            Mystical
                                        </p>
                                        <div className='LP-personality-accordion-arrow'></div>
                                    </div>
                                    {/* Conditionally render the text */}
                                    <p className='LP-personality-accordion-text'>
                                        Compassionate, imaginative, and playful. This personality takes a spiritual approach to expanding the mind, focusing on 'how' you learn rather than 'what'.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Content for Voice */}
                    <div
                        className="LP-content-section"
                        ref={(el) => (tileRefs.current[2] = el)}
                    >
                        <div
                            className='LP-feature-tile LP-voice-feature-tile'
                        >

                            <p className='LP-meet-ai-title LP-voice-title'>
                                <span>Hear me speak. Listen closely.</span> Bring your tutor to life by equiping your tutor one of our six voice options.
                            </p>

                            <i className="fa-solid fa-seedling LP-meet-ai-seedling"></i>

                            <div className="LP-voice-container">
                                <div className="LP-alloy-container">
                                    <div className="LP-voice-select-container">
                                        <p className="LP-when-to-enhance-search">
                                            Bonnie
                                        </p>
                                    </div>
                                    <div className="LP-voice-test-container">
                                        <div 
                                            className="LP-play-button"
                                            id="LP-play-alloy"
                                            onClick={() => handlePlayPause('alloy')}
                                        >
                                            <i className={`bi ${isPlaying.alloy ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>
                                        </div>
                                        <div className="LP-sound-wave-container">
                                            <div id="LP-waveform-alloy" className="LP-sound-wave"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="LP-alloy-container">
                                    <div className="LP-voice-select-container">
                                        <p className="LP-when-to-enhance-search">
                                            Wilson
                                        </p>
                                    </div>
                                    <div className="LP-voice-test-container">
                                        <div 
                                            className="LP-play-button"
                                            id="LP-play-echo"
                                            onClick={() => handlePlayPause('echo')}
                                        >
                                            <i className={`bi ${isPlaying.echo ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>

                                        </div>
                                        <div className="LP-sound-wave-container">
                                            <div id="LP-waveform-echo" className="LP-sound-wave"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="LP-alloy-container">
                                    <div className="LP-voice-select-container">
                                        <p className="LP-when-to-enhance-search">
                                            Kam
                                        </p>
                                    </div>
                                    <div className="LP-voice-test-container">
                                        <div 
                                            className="LP-play-button"
                                            id="LP-play-fable"
                                            onClick={() => handlePlayPause('fable')}
                                        >
                                            <i className={`bi ${isPlaying.fable ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>

                                        </div>
                                        <div className="LP-sound-wave-container">
                                            <div id="LP-waveform-fable" className="LP-sound-wave"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="LP-alloy-container">
                                    <div className="LP-voice-select-container">
                                        <p className="LP-when-to-enhance-search">
                                            Jeremiah
                                        </p>
                                    </div>
                                    <div className="LP-voice-test-container">
                                        <div 
                                            className="LP-play-button"
                                            id="LP-play-onyx"
                                            onClick={() => handlePlayPause('onyx')}
                                        >
                                            <i className={`bi ${isPlaying.onyx ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>
                                        </div>
                                        <div className="LP-sound-wave-container">
                                            <div id="LP-waveform-onyx" className="LP-sound-wave"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="LP-alloy-container">
                                    <div className="LP-voice-select-container">
                                        <p className="LP-when-to-enhance-search">
                                            Vienna
                                        </p>
                                    </div>
                                    <div className="LP-voice-test-container">
                                        <div 
                                            className="LP-play-button"
                                            id="LP-play-nova"
                                            onClick={() => handlePlayPause('nova')}
                                        >
                                            <i className={`bi ${isPlaying.nova ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>
                                        </div>
                                        <div className="LP-sound-wave-container">
                                            <div id="LP-waveform-nova" className="LP-sound-wave"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="LP-alloy-container">
                                    <div className="LP-voice-select-container">
                                        <p className="LP-when-to-enhance-search">
                                            Heather
                                        </p>
                                    </div>
                                    <div className="LP-voice-test-container">
                                        <div 
                                            className="LP-play-button"
                                            id="LP-play-shimmer"
                                            onClick={() => handlePlayPause('shimmer')}
                                        >
                                            <i className={`bi ${isPlaying.shimmer ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>
                                        </div>
                                        <div className="LP-sound-wave-container">
                                            <div id="LP-waveform-shimmer" className="LP-sound-wave"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Content for About You section */}
                    <div
                        className="LP-content-section"
                        ref={(el) => (tileRefs.current[3] = el)}
                    >
                        <div
                            className='LP-feature-tile LP-about-you-feature-tile'
                        >

                            <p className='LP-meet-ai-title LP-about-you-title'>
                                <span>Tailored guidance.</span> Your tutor understands your unique journey and context.
                            </p> 

                            <i className="fa-solid fa-seedling LP-meet-ai-seedling"></i>

                            <div className='about-you-questions-outer-container'>
                                <div className='about-you-questions-inner-container'>
                                    <p className='about-you-question-text' id='question-1'>
                                        What's your name?
                                    </p>
                                    <p className='about-you-question-text' id='question-2'>
                                        Who are you?
                                    </p>
                                    <p className='about-you-question-text' id='question-3'>
                                        Who do you want to be 5 years from now?
                                    </p>
                                    <p className='about-you-question-text' id='question-4'>
                                        What's your goal?
                                    </p>
                                    <p className='about-you-question-text' id='question-5'>
                                        What motivates you?
                                    </p>
                                    <p className='about-you-question-text' id='question-6'>
                                        What's your passion?
                                    </p>
                                    <p className='about-you-question-text' id='question-7'>
                                        How old are you?
                                    </p>
                                    <p className='about-you-question-text' id='question-8'>
                                        How do you spend your time?
                                    </p>
                                    <p className='about-you-question-text' id='question-9'>
                                        What's your occupation?
                                    </p>
                                    <p className='about-you-question-text' id='question-10'>
                                        What hobbies do you have?
                                    </p>
                                    <p className='about-you-question-text' id='question-11'>
                                        What are your strengths?
                                    </p>
                                    <p className='about-you-question-text' id='question-12'>
                                        What are your weaknesses?
                                    </p>
                                    <p className='about-you-question-text' id='question-13'>
                                        How do you like to learn?
                                    </p>
                                    <p className='about-you-question-text' id='question-14'>
                                        What would you like to get better at?
                                    </p>
                                    <p className='about-you-question-text' id='question-15'>
                                        What's your dream job?
                                    </p>
                                    <p className='about-you-question-text' id='question-16'>
                                        What motivates you?
                                    </p>
                                    <p className='about-you-question-text' id='question-17'>
                                        What are the top 5 most important things in your life?
                                    </p>
                                    <p className='about-you-question-text' id='question-18'>
                                        What gives you meaning?
                                    </p>
                                    <p className='about-you-question-text' id='question-19'>
                                        What are your top 5 life goals?
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Content for Privacy */}
                    <div
                        className="LP-content-section"
                        ref={(el) => (tileRefs.current[4] = el)}
                    >
                        <div
                            className='LP-feature-tile LP-privacy-feature-tile'
                        >
                            <p className='LP-meet-ai-title LP-privacy-title'>
                                <span>Paradigm confidentiality.</span> Everything stays between you and your AI. We will never sell your data to a third party.
                            </p>

                            <i className="fa-solid fa-seedling LP-meet-ai-seedling"></i>

                            <div className='LP-privacy-container'>
                                <i className="bi bi-lock-fill LP-lock-icon"></i>
                                <div className='privacy-dots-container'>
                                    <i className="bi bi-circle-fill LP-lock-circle-icon"></i>
                                    <i className="bi bi-circle-fill LP-lock-circle-icon"></i>
                                    <i className="bi bi-circle-fill LP-lock-circle-icon"></i>
                                    <i className="bi bi-circle-fill LP-lock-circle-icon"></i>
                                    <i className="bi bi-circle-fill LP-lock-circle-icon"></i>
                                    <i className="bi bi-circle-fill LP-lock-circle-icon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    );
}

export default CreateTutor2LP;