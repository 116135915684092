// Import React and the CSS file
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../helpers/AuthProvider';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import useWindowSize from '../helpers/useWindowSize';
import './LandingPage5.css';

import paradigmLogo from '../../assets/images/logo-inspiration-compass-image-2-removebg-preview.png';
import copilotMascotImage from '../../assets/landingPageImages/copilot-mascot.png';
import ArtistShowcaseLP from './ArtistShowcaseLP/ArtistShowcaseLP';
import mindMapVideo from '../../assets/videos/mind-map-screenrecording-v1.mp4';

import ethosImage from '../../assets/landingPageImages/ethos-midjourney3.png';

import CreateTutor2LP from './CreateTutorLP/CreateTutor2LP.js';
// import CreateTutorLP from './CreateTutorLP/CreateTutorLP.js';
import CopilotLP from './CopilotLP/CopilotLP';
import StudioLP from './StudioLP/StudioLP';
import MindMapLP from './MindMapLP/MindMapLP';
// import AboutLP from './AboutLP/AboutLP';
import TestimonialsLP from './TestimonialsLP/TestimonialsLP';
import PricingLP from './PricingLP/PricingLP';

import copilotImage from '../../assets/landingPageImages/copilot.png';
import studioImage from '../../assets/landingPageImages/design-studio-inspo.jpeg';
import nightSkyImage from '../../assets/landingPageImages/night-sky-sun.png';

gsap.registerPlugin(ScrollTrigger);

function LandingPage5() {
    const landingPageRef = useRef(null);

    const [showBanner, setShowBanner] = useState(false);
    const [bannerLocked, setBannerLocked] = useState(false); // This will "lock" the banner state during the mindmap section

    const netflixBackgroundRef = useRef(null);

    const mindmapTitleContainerRef = useRef(null);
    const mindmapCanvasRef = useRef(null);

    const aboutTitleRef = useRef(null);

    const testimonialTitleRef = useRef(null);
    const testimonial1Ref = useRef(null);
    const testimonial2Ref = useRef(null);
    const testimonial3Ref = useRef(null);
    const testimonial4Ref = useRef(null);
    const testimonial5Ref = useRef(null);
    const testimonial6Ref = useRef(null);
    const testimonial7Ref = useRef(null);
    const testimonial8Ref = useRef(null);
    const testimonialButtonRef = useRef(null);

    useEffect(() => {
        const handleFontsLoaded = () => {
          document.body.classList.add('fonts-loaded');
        };
      
        if (document.fonts && document.fonts.ready) {
          document.fonts.ready.then(handleFontsLoaded);
        } else {
          window.addEventListener('load', handleFontsLoaded);
          return () => {
            window.removeEventListener('load', handleFontsLoaded);
          };
        }
    }, []);


    // After sign in
    const { user } = useAuth(); // Use the user object from AuthContext
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        if (user) { // If the user object is not null, it means the user is authenticated
            console.log("User is authenticated, navigating to studio...");
            navigate('/studio');
        }
    }, [user, navigate]); // Depend on user and navigate to ensure updates trigger the effect

    const { width } = useWindowSize();

    // Scroll banner (Hide when in Mind Map section)
    const handleScroll = () => {
        const mindmapTitleContainer = mindmapTitleContainerRef.current;
    
        if (mindmapTitleContainer) {
            // Get the computed style to check the current z-index value
            const zIndex = window.getComputedStyle(mindmapTitleContainer).zIndex;
    
            // If the z-index is 1 (mindmap section active)
            if (parseInt(zIndex) === 1) {
                console.log("Mindmap section active, hiding banner");
                setShowBanner(false); // Hide the banner when the mindmap section is active
                setBannerLocked(true); // Lock the banner in hidden state
            } else {
                setBannerLocked(false); // Unlock the banner behavior for normal scroll behavior
                if (window.scrollY > 0) {
                    console.log("Showing banner (scroll down)");
                    setShowBanner(true); // Show the banner during normal scroll behavior
                } else {
                    console.log("Hiding banner (scroll up)");
                    setShowBanner(false); // Hide the banner at the top of the page
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    // Nav links
    const scrollToSection = (selector, offset = 0) => {
        const element = document.querySelector(selector);
        if (element) {
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - offset;
            window.scrollTo({
                top: topPos,
                behavior: 'smooth'
            });
        }
    };


    // Load in animations
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const delay = entry.target.getAttribute('data-animation-delay') || 0;
    
                        // GSAP timeline to animate with staggered/overlapping animations
                        const tl = gsap.timeline({
                            defaults: { duration: 1, ease: 'power2.out' }
                        });
    
                        // GSAP animation for each target with delay from data attribute
                        tl.fromTo(
                            entry.target,
                            { opacity: 0, y: 20 }, // Starting state
                            { opacity: 1, y: 0 },   // Final state
                            `+=${delay}` // Use the delay from the data attribute
                        );
    
                        // Stop observing the target after the animation
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,  // Trigger when 50% of the element is visible
            }
        );

        // Ref for elements
        const refs = [
            aboutTitleRef, 
            testimonialTitleRef, 
            testimonial1Ref, 
            testimonial2Ref, 
            testimonial3Ref, 
            testimonial4Ref, 
            testimonial5Ref, 
            testimonial6Ref, 
            testimonial7Ref, 
            testimonial8Ref, 
            testimonialButtonRef
        ];
        
        refs.forEach(ref => {
            if (ref.current) observer.observe(ref.current);
        });
    
        return () => {
            refs.forEach(ref => {
                if (ref.current) observer.unobserve(ref.current);
            });
        };
    }, []);

    return (
        <div className="landing-page preload" ref={landingPageRef}>

            <nav className="navbar">
                <div className="LP-navbar-left">
                    <div className="LP-logo-container">
                        <img
                            src={paradigmLogo}
                            alt="Paradigm Logo"
                            className="LP-paradigm-logo"
                        />
                        <p className="navbar-logo-text">Paradigm</p>
                        <p className="navbar-logo-x">X</p>
                    </div>
                </div>
                <div className="navbar-right">
                    <button className="landing-page-button-2 button-secondary" onClick={() => window.location.href = 'https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2'}>
                        Log in
                    </button>
                    <button className="landing-page-button-1 button-primary" onClick={() => window.location.href = 'https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2'}>
                        {width < 480 ? 'Log in' : 'Sign up'}
                    </button>
                </div>
            </nav>

            <div className="main">

                <div className='hero-section-2'>
                    <img 
                        className="hero-2-image"
                        src={ethosImage} 
                        alt="Hero Image"
                    />
                    <div className="hero-2-text-container">
                        <p className="hero-2-title">
                            The privilege
                        </p>
                        <p className="hero-2-title">
                            of a lifetime
                        </p>
                        <p className="hero-2-title">
                            is to
                        </p>
                        <p className="hero-2-title">
                            become
                        </p>
                        <p className="hero-2-title">
                            who you truly are.
                        </p>
                        <button 
                            className="hero-button landing-page-button-1 button-primary" 
                            onClick={() => alert('Get started!')}
                        >
                            Accept free trial
                        </button>
                    </div>
                </div>

                <div className="features-section">
                    {/* Create Your AI 2 Section */}
                    <CreateTutor2LP />
                    
                    {/* Create Your AI Section */}
                    {/*
                    <CreateTutorLP />
                    */}

                    {/* Copilot Section */}
                    <CopilotLP
                        landingPageRef={landingPageRef}
                    />

                    {/* Studio Section */}
                    <StudioLP 
                        landingPageRef={landingPageRef} 
                        mindmapTitleContainerRef={mindmapTitleContainerRef}
                        mindmapCanvasRef={mindmapCanvasRef}
                    />

                    {/* Introducing Mind Mao Screen */}
                    <div className='mind-map-title-container' ref={mindmapTitleContainerRef}>
                        <p className='mind-map-title-1'>Introducing</p>
                        <p className='mind-map-title-2'>Mind Map</p>
                    </div>

                    {/* Mind Map Section */}
                    <MindMapLP 
                        landingPageRef={landingPageRef} 
                        mindmapTitleContainerRef={mindmapTitleContainerRef}
                        mindmapCanvasRef={mindmapCanvasRef}
                    />
                </div>

                {/* About Section */}
                {/*
                <AboutLP 
                    aboutTitleRef={aboutTitleRef} 
                />
                */}

                {/* Testimonials Section */}
                <TestimonialsLP 
                    width={width}
                    testimonialTitleRef={testimonialTitleRef}
                    testimonial1Ref={testimonial1Ref}
                    testimonial2Ref={testimonial2Ref}
                    testimonial3Ref={testimonial3Ref}
                    testimonial4Ref={testimonial4Ref}
                    testimonial5Ref={testimonial5Ref}
                    testimonial6Ref={testimonial6Ref}
                    testimonial7Ref={testimonial7Ref}
                    testimonial8Ref={testimonial8Ref}
                    testimonialButtonRef={testimonialButtonRef}
                />

                {/* Pricing Section */}
                <PricingLP />

                <div className="footer-section">
                    <div className="footer-seperator"></div>
                    <div className="footer-content-container">
                        <p>© 2025 All Rights Reserved, Paradigm®</p>
                        <div className="terms-container">
                            <p>Privacy policy</p>
                            <p>|</p>
                            <p>Terms of use</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`bottom-banner ${showBanner ? 'show' : 'hide'}`}>
                <p className="bottom-banner-text">In the game of life, you are the main character.</p>
                <button 
                    className="LP-bottom-banner-button landing-page-button-1 button-primary" 
                    onClick={() => alert('Get started!')}
                >
                    Accept free trial
                </button>
            </div>

        </div>
    );
}

export default LandingPage5;