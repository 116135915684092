import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import './AnswerChecking.css';

function AnswerChecking() {
  const containerRef = useRef(null);

  useEffect(() => {
    // Animate the container pop-in
    gsap.fromTo(
      containerRef.current,
      { scale: 0.5, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
    );
  }, []);

  return (
    <div ref={containerRef} className="answer-checking-container">
      <p className="checking-text">Checking Answer</p>
      <div className="dots-container">
        <span className="dot">.</span>
        <span className="dot">.</span>
        <span className="dot">.</span>
        <span className="dot">.</span>
        <span className="dot">.</span>
      </div>
    </div>
  );
}

export default AnswerChecking;