import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './IncorrectAnswer.css';

function IncorrectAnswer({ explanation, onClose, isLastQuestion }) {
  const containerRef = useRef(null);
  const crossRef = useRef(null);

  useEffect(() => {
    // Animate the container pop-in
    gsap.fromTo(
      containerRef.current,
      { scale: 0.5, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
    );
  }, []);

  return (
    <div ref={containerRef} className="incorrect-answer-container">
      <div ref={crossRef} className="cross-icon">
        <i className="bi bi-x-circle-fill"></i>
      </div>
      <p className="explanation-text">{explanation}</p>
      <button className="next-button-incorrect" onClick={onClose}>
        {isLastQuestion ? "Show Results" : "Next Question"}
      </button>
    </div>
  );
}

export default IncorrectAnswer;
