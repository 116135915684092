import React from 'react';
import './TestimonialsLP.css';

function TestimonialsLP({ 
    width,
    testimonialTitleRef, 
    testimonial1Ref, 
    testimonial2Ref, 
    testimonial3Ref, 
    testimonial4Ref, 
    testimonial5Ref, 
    testimonial6Ref, 
    testimonial7Ref, 
    testimonial8Ref, 
    testimonialButtonRef
}) {

    return (
        <div className="testimonials-section">
            <p ref={testimonialTitleRef} className="testimonials-title">The Paradigm community.</p>
            <div className="testimonials-container">
                {width > 912 ? (
                // Desktop view: 4 columns
                    <>
                        <div className="testimonials-column">
                            <div ref={testimonial1Ref} className="testimonial turquoise-gradient" data-animation-delay="0.2">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    This has absolutely revolutionized how I learn and how I work. I have been able to use the My Tutor feature to further my language learning journey, develop emails for work with acute articulation and speed, and best of all it's been teaching me how to program. 
                                </p>
                            </div>
                            <div ref={testimonial2Ref} className="testimonial yellow-gradient" data-animation-delay="0.4">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    The My Tutor Copilot AI Assistant is 1000x better than Copilot by itself.
                                </p>
                            </div>
                        </div>
                        <div className="testimonials-column">
                            <div ref={testimonial3Ref} className="testimonial green-gradient" data-animation-delay="0.4">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    I'm an A-level student and I can't even tell you how many hours of revision I have saved by having copilot simplify the topics for me. My teachers always recommend so many long videos to watch, I don't know how I lived without YouTube vision before, or why YouTube doesn't already have those features tbh.
                                </p>
                            </div>
                            <div ref={testimonial4Ref} className="testimonial purple-gradient" data-animation-delay="0.6">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    Originally I was quite skeptical about using an AI on my browser but I've found copilot to really help me out in ways I hadn't originally thought possible. It really helps alleviate the extra stress from being at uni and would definitely recommend for others wanting to save time and discover different options for their desktop.
                                </p>
                            </div>
                        </div>
                        <div className="testimonials-column">
                            <div ref={testimonial5Ref} className="testimonial pink-gradient" data-animation-delay="0.6">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    This thing is insane. Seriously, I am so incredibly grateful to the people who spent time to make this. I cannot stress enough how much I love the My Tutor feature in this specific extension. I've been telling literally everyone I know about it. I've gotten my coworkers and family members to try it too. 
                                </p>
                            </div>
                            <div ref={testimonial6Ref} className="testimonial orange-gradient" data-animation-delay="0.8">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    This is genius! I always use YouTube tutorials to learn, reading the key points and the moment finder has changed my life and saves me so much time so I'm very productive with my time. Recommended to all friends and family they love it too. It's just better.
                                </p>
                            </div>
                        </div>
                        <div className="testimonials-column">
                            <div ref={testimonial7Ref} className="testimonial red-gradient" data-animation-delay="0.8">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    I'm a student at university and came across this on titktok. In love with it atm, studying business and this helps out loads with my essays and research. Takes the pressure off and makes it fun
                                </p>
                            </div>
                            <div ref={testimonial8Ref} className="testimonial blue-gradient" data-animation-delay="1">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    Fantastic product, fast and easy information right at your fingertips. The Youtube vision feature is a game changer if you are trying to learn through video and sift through the waffle.
                                </p>
                            </div>
                        </div>
                    </>
                ) : width > 540 ? (
                    // Tablet view: 3 columns
                    <>
                        <div className="testimonials-column">
                            {/* 1st Column Testimonials */}
                            <div ref={testimonial1Ref} className="testimonial turquoise-gradient" data-animation-delay="0.2">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    This has absolutely revolutionized how I learn and how I work. I have been able to use the My Tutor feature to further my language learning journey, develop emails for work with acute articulation and speed, and best of all it's been teaching me how to program. 
                                </p>
                            </div>
                            <div ref={testimonial2Ref} className="testimonial yellow-gradient" data-animation-delay="0.4">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    The My Tutor Copilot AI Assistant is 1000x better than Copilot by itself.
                                </p>
                            </div>
                            <div ref={testimonial3Ref} className="testimonial red-gradient" data-animation-delay="0.6">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    I'm a student at university and came across this on titktok. In love with it atm, studying business and this helps out loads with my essays and research. Takes the pressure off and makes it fun
                                </p>
                            </div>
                        </div>
                        <div className="testimonials-column">
                            {/* 2nd Column Testimonials */}
                            <div ref={testimonial4Ref} className="testimonial green-gradient" data-animation-delay="0.4">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    I'm an A-level student and I can't even tell you how many hours of revision I have saved by having copilot simplify the topics for me. My teachers always recommend so many long videos to watch, I don't know how I lived without YouTube vision before, or why YouTube doesn't already have those features tbh.
                                </p>
                            </div>
                            <div ref={testimonial5Ref} className="testimonial purple-gradient" data-animation-delay="0.6">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    Originally I was quite skeptical about using an AI on my browser but I've found copilot to really help me out in ways I hadn't originally thought possible. It really helps alleviate the extra stress from being at uni and would definitely recommend for others wanting to save time and discover different options for their desktop.
                                </p>
                            </div>
                            <div ref={testimonial6Ref} className="testimonial blue-gradient" data-animation-delay="0.8">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    Fantastic product, fast and easy information right at your fingertips. The Youtube vision feature is a game changer if you are trying to learn through video and sift through the waffle.
                                </p>
                            </div>
                        </div>
                        <div className="testimonials-column">
                            {/* 3rd Column Testimonials */}
                            <div ref={testimonial7Ref} className="testimonial pink-gradient" data-animation-delay="0.6">
                                <div className="testimonial pink-gradient">
                                    <div>
                                        <i className="bi bi-quote"></i>
                                    </div>
                                    <p className="testimonial-text">
                                        This thing is insane. Seriously, I am so incredibly grateful to the people who spent time to make this. I cannot stress enough how much I love the My Tutor feature in this specific extension. I've been telling literally everyone I know about it. I've gotten my coworkers and family members to try it too. 
                                    </p>
                                </div>
                            </div>
                            <div ref={testimonial8Ref} className="testimonial orange-gradient" data-animation-delay="0.8">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    This is genius! I always use YouTube tutorials to learn, reading the key points and the moment finder has changed my life and saves me so much time so I'm very productive with my time. Recommended to all friends and family they love it too. It's just better.
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    // Mobile view: 2 columns
                    <>
                        <div className="testimonials-column">
                            <div ref={testimonial1Ref} className="testimonial turquoise-gradient" data-animation-delay="0.2">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    This has absolutely revolutionized how I learn and how I work. I have been able to use the My Tutor feature to further my language learning journey, develop emails for work with acute articulation and speed, and best of all it's been teaching me how to program. 
                                </p>
                            </div>
                            <div ref={testimonial2Ref} className="testimonial yellow-gradient" data-animation-delay="0.4">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    The My Tutor Copilot AI Assistant is 1000x better than Copilot by itself.
                                </p>
                            </div>
                            <div ref={testimonial3Ref} className="testimonial green-gradient" data-animation-delay="0.6">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    I'm an A-level student and I can't even tell you how many hours of revision I have saved by having copilot simplify the topics for me. My teachers always recommend so many long videos to watch, I don't know how I lived without YouTube vision before, or why YouTube doesn't already have those features tbh.
                                </p>
                            </div>
                            <div ref={testimonial4Ref} className="testimonial purple-gradient" data-animation-delay="0.8">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    Originally I was quite skeptical about using an AI on my browser but I've found copilot to really help me out in ways I hadn't originally thought possible. It really helps alleviate the extra stress from being at uni and would definitely recommend for others wanting to save time and discover different options for their desktop.
                                </p>
                            </div>
                        </div>
                        <div className="testimonials-column">
                            <div ref={testimonial5Ref} className="testimonial pink-gradient" data-animation-delay="0.4">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    This thing is insane. Seriously, I am so incredibly grateful to the people who spent time to make this. I cannot stress enough how much I love the My Tutor feature in this specific extension. I've been telling literally everyone I know about it. I've gotten my coworkers and family members to try it too. 
                                </p>
                            </div>
                            <div ref={testimonial6Ref} className="testimonial orange-gradient" data-animation-delay="0.6">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    This is genius! I always use YouTube tutorials to learn, reading the key points and the moment finder has changed my life and saves me so much time so I'm very productive with my time. Recommended to all friends and family they love it too. It's just better.
                                </p>
                            </div>
                            <div ref={testimonial7Ref} className="testimonial red-gradient" data-animation-delay="0.8">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    I'm a student at university and came across this on titktok. In love with it atm, studying business and this helps out loads with my essays and research. Takes the pressure off and makes it fun
                                </p>
                            </div>
                            <div ref={testimonial8Ref} className="testimonial blue-gradient" data-animation-delay="1">
                                <div>
                                    <i className="bi bi-quote"></i>
                                </div>
                                <p className="testimonial-text">
                                    Fantastic product, fast and easy information right at your fingertips. The Youtube vision feature is a game changer if you are trying to learn through video and sift through the waffle.
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div ref={testimonialButtonRef} className="community-button-container">
                <button className="LP-community-button button-secondary fill-available-button" onClick={() => alert('Login')}>
                    Join the Paradigm community
                </button>
            </div>
        </div>
    );
}

export default TestimonialsLP;