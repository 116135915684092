import React from 'react';
import './ArtistShowcaseLP.css';

// Artist showcase images
import portraitAbstractShapes from '../../../assets/artistShowcase/artist-showcase-portrait-abstract-shapes.jpg';
import portraitAbstractShapes2 from '../../../assets/artistShowcase/artist-showcase-portrait-abstract-shapes-2.jpg';
import portraitBallerina from '../../../assets/artistShowcase/artist-showcase-portrait-ballerina.jpg';
import portraitBallerina2 from '../../../assets/artistShowcase/artist-showcase-portrait-ballerina-2.jpg';
import portraitCyberpunkVisorGuy from '../../../assets/artistShowcase/artist-showcase-portrait-cyberpunk-visor-guy.jpg';
import portraitCyberpunkVisorGuy2 from '../../../assets/artistShowcase/artist-showcase-portrait-cyberpunk-visor-guy-2.jpg';
import portraitCyberpunkVisorGuy3 from '../../../assets/artistShowcase/artist-showcase-portrait-cyberpunk-visor-guy-3.jpg';
import portraitDapperOlderGuy from '../../../assets/artistShowcase/artist-showcase-portrait-dapper-older-guy.jpg';
import portraitDarkCurlyHair from '../../../assets/artistShowcase/artist-showcase-portrait-dark-curly-hair.jpg';
import portraitElderlyWoman from '../../../assets/artistShowcase/artist-showcase-portrait-elderly-woman.jpg';
import portraitElderlyWoman2 from '../../../assets/artistShowcase/artist-showcase-portrait-elederly-woman-2.jpg';
import portraitFemaleWarrior from '../../../assets/artistShowcase/artist-showcase-portrait-female-warrior.jpg';
import portraitFemaleWarrior2 from '../../../assets/artistShowcase/artist-showcase-portrait-female-warrior-2.jpg';
import portraitGraffitiArtist from '../../../assets/artistShowcase/artist-showcase-portrait-graffiti-artist.jpg';
import portraitGraffitiArtist2 from '../../../assets/artistShowcase/artist-showcase-portrait-graffiti-artist-2.jpg';
import portraitJazzMusician from '../../../assets/artistShowcase/artist-showcase-portrait-jazz-musician.jpg';
import portraitMiddleAgedTraveller from '../../../assets/artistShowcase/artist-showcase-portrait-middle-aged-traveller.jpg';
import portraitMiddleAgedTraveller2 from '../../../assets/artistShowcase/artist-showcase-portrait-middle-aged-traveller-2.jpg';
import portraitRenaissanceWoman from '../../../assets/artistShowcase/artist-showcase-portrait-renaissance-woman.jpg';
import portraitRenaissanceWoman2 from '../../../assets/artistShowcase/artist-showcase-portrait-renaissance-woman-2.jpg';
import portraitSteampunk from '../../../assets/artistShowcase/artist-showcase-portrait-steampunk.jpg';
import portraitSurrealistFlowy from '../../../assets/artistShowcase/artist-showcase-portrait-surrealist-flowy.jpg';
import portraitSurrealistSpaceFlowers from '../../../assets/artistShowcase/artist-showcase-portrait-surrealist-girl-space-flowers.jpg';

import animalElephant from '../../../assets/artistShowcase/artist-showcase-animal-elephant.jpg';
import animalLion from '../../../assets/artistShowcase/artist-showcase-animal-lion.jpg';

import landscapeCanada from '../../../assets/artistShowcase/artist-showcase-landscape-canada.jpg';
import landscapeCoastal from '../../../assets/artistShowcase/artist-showcase-landscape-coastal.jpg';
import landscapeJungle from '../../../assets/artistShowcase/artist-showcase-landscape-jungle.jpg';

import cartoonHoverboard from '../../../assets/artistShowcase/artist-showcase-cartoon-hoverboard.jpg';
import cartoonPigtailGirl from '../../../assets/artistShowcase/artist-showcase-cartoon-pigtail-girl.jpg';
import cartoonSuperheroGirl from '../../../assets/artistShowcase/artist-showcase-cartoon-superhero-girl.jpg';
import cartoonSuperheroGirl2 from '../../../assets/artistShowcase/artist-showcase-cartoon-superhero-girl-2.jpg';
import cartoonTypewriter from '../../../assets/artistShowcase/artist-showcase-cartoon-typewriter.jpg';

import drawingHandClock from '../../../assets/artistShowcase/artist-showcase-drawing-hand-clock.jpg';
import drawingMagicBook from '../../../assets/artistShowcase/artist-showcase-drawing-magic-book.jpg';
import drawingTypewriter from '../../../assets/artistShowcase/artist-showcase-drawing-typewriter.jpg';

import animeSamurai from '../../../assets/artistShowcase/artist-showcase-anime-samurai.jpg';
import animeSpaceshipCockpit from '../../../assets/artistShowcase/artist-showcase-anime-spaceship-cockpit.jpg';
import animeWarriorCherryBlossoms from '../../../assets/artistShowcase/artist-showcase-anime-warrior-cherry-blossoms.jpg';
import animeWarriorCherryBlossoms2 from '../../../assets/artistShowcase/artist-showcase-anime-warrior-cherry-blossoms-2.jpg';

import posterSpace from '../../../assets/artistShowcase/artist-showcase-poster-space.jpg';
import posterMusicFestival from '../../../assets/artistShowcase/artist-showcase-poster-music-festival.jpg';
import posterScifiCity from '../../../assets/artistShowcase/artist-showcase-poster-sci-fi-city-robot.jpg';
import posterVintageRobot from '../../../assets/artistShowcase/artist-showcase-poster-vintage-robot-sci-fi.jpg';

import digitalArtCyberpunkCity from '../../../assets/artistShowcase/artist-showcase-digital-art-cyberpunk-city.jpg';
import digitalArtFloatingIsland from '../../../assets/artistShowcase/artist-showcase-digital-art-floating-island.jpg';
import digitalArtUnderwaterCity from '../../../assets/artistShowcase/artist-showcase-digital-art-underwater-city.jpg';

import logoDroneDelivery from '../../../assets/artistShowcase/artist-showcase-logo-drone-delivery-aerofleet.jpg';
import logoNeuronix from '../../../assets/artistShowcase/artist-showcase-logo-neuronix.jpg';
import logoSolaris from '../../../assets/artistShowcase/artist-showcase-logo-solar-energy-solaris.jpg';

import architectureGreenCity from '../../../assets/artistShowcase/artist-showcase-architecture-green-city.jpg';
import architectureGlassTowers from '../../../assets/artistShowcase/artist-showcase-architecture-glass-towers.jpg';
import architectureCliffsideVilla from '../../../assets/artistShowcase/artist-showcase-architecture-cliffside-villa.jpg';
import architectureCliffsideVilla2 from '../../../assets/artistShowcase/artist-showcase-architecture-cliffside-villa-2.jpg';


function ArtistShowcaseLP({ netflixBackgroundRef }) {

    return (
        <div className='netflix-background-container' ref={netflixBackgroundRef}>
            <div className='netflix-gradient'></div>
            <div className='netflix-container-perspective'>
                <div className='netflix-container-background'>
                    <div className='netflix-row'>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                    </div>
                    <div className='netflix-row'>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animalElephant})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitAbstractShapes})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitBallerina})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitCyberpunkVisorGuy})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDapperOlderGuy})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitElderlyWoman})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitFemaleWarrior})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitGraffitiArtist})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitJazzMusician})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitMiddleAgedTraveller})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitRenaissanceWoman})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitSteampunk})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitSurrealistFlowy})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animalLion})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCoastal})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeJungle})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonPigtailGirl})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonTypewriter})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingMagicBook})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingTypewriter})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSpaceshipCockpit})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeWarriorCherryBlossoms})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeWarriorCherryBlossoms})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterMusicFestival})` }}></div>
                    </div>
                    <div className='netflix-row'>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitCyberpunkVisorGuy2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitElderlyWoman2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterScifiCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterVintageRobot})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtFloatingIsland})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtUnderwaterCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoDroneDelivery})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoSolaris})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureCliffsideVilla})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitAbstractShapes2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitBallerina2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitCyberpunkVisorGuy3})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitFemaleWarrior2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitGraffitiArtist2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitMiddleAgedTraveller2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitRenaissanceWoman2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitSurrealistSpaceFlowers})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeWarriorCherryBlossoms2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl2})` }}></div>
                    </div>
                    <div className='netflix-row'>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureCliffsideVilla2})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                    </div>
                    <div className='netflix-row'>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                    </div>
                    <div className='netflix-row'>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                    </div>
                    <div className='netflix-row'>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                    </div>
                    <div className='netflix-row'>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                    </div>
                    <div className='netflix-row'>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                    </div>
                    <div className='netflix-row'>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                        <div className='netflix-thumbnail'></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ArtistShowcaseLP;