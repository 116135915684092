import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './MindMapLP.css';

import mindMapVideo from '../../../assets/videos/mind-map-screenrecording-v1.mp4';

gsap.registerPlugin(ScrollTrigger);

function MindMapLP({ landingPageRef, mindmapTitleContainerRef, mindmapCanvasRef }) {
    const mindmapSectionRef = useRef(null);
    const mindmapTextRef = useRef(null); // Ref for the mind-map-text container
    const spansRefs = useRef([]); // Array of refs for each span

    // Initialize GSAP animation for mind-map-text spans and reverse mind-map-canvas
    const initMindMapTextAnimation = () => {
        const mindmapTitleContainer = mindmapTitleContainerRef.current;
        const spans = spansRefs.current;
        const mindmapSection = mindmapSectionRef.current;
        const mindmapCanvas = mindmapCanvasRef.current;
        const landingPage = landingPageRef.current;
    
        if (spans.length && mindmapSection && mindmapCanvas && landingPage) {
            // Timeline for span opacity animation
            const textTl = gsap.timeline({
                scrollTrigger: {
                    trigger: mindmapSection,
                    start: 'top bottom', // Start when mindmap section enters view
                    end: 'bottom top', // Continue until the section has completely scrolled through
                    scrub: true, // Smoothly follow the scroll
                    invalidateOnRefresh: true, // Ensures everything is reset properly when scrolling fast
                    // markers: true, // Uncomment for debugging
                }
            });
    
            // Animate each span's opacity sequentially
            spans.forEach((span, index) => {
                textTl.fromTo(
                    span,
                    { opacity: 0.15 },
                    { opacity: 1, duration: 0.3 },
                    index * 0.4 // Stagger timing for each span
                ).to(span, { opacity: 0.15, duration: 0.3 }); // Fade back out
            });
    
            // Separate ScrollTrigger for mind-map canvas and landing page background
            const backgroundTl = gsap.timeline({
                scrollTrigger: {
                    trigger: mindmapSection,
                    start: 'bottom-=20% top', // Trigger fade-out quicker
                    end: 'bottom+=30% top', // Shorter duration for exit
                    scrub: 1, // Faster scrub for smoother and quicker transition
                    invalidateOnRefresh: true, // Ensures everything is reset properly when scrolling fast
                    // markers: true, // Uncomment for debugging
                }
            });
    
            // Add mindmapCanvas opacity and z-index reset to the same timeline
            backgroundTl.to(mindmapCanvas, {
                opacity: 0,
                duration: 1.5,
             
            }, '-=1.5'); // Sync both animations

            // Add mindmapTitleContainer z-index reset to end of same timeline
            backgroundTl.to(mindmapTitleContainer, {
                zIndex: -1, // Ensure it's sent behind
                duration: 0,
            }, '-=0'); // Sync both animations

            // Add mindmapCanvas z-index reset to end of same timeline
            backgroundTl.to(mindmapCanvas, {
                zIndex: -1, // Ensure it's sent behind
                duration: 0,
            }, '-=0'); // Sync both animations

            // Add background color transition to timeline
            backgroundTl.to(landingPage, {
                backgroundColor: 'white', // Smooth transition to white
                duration: 1.5,
            }, '-=0');
        }
    };

    useEffect(() => {
        let ctx = gsap.context(() => {
            initMindMapTextAnimation();
        }, []);
    
        return () => {
            ctx.revert();
        };
    }, []);

    return (
        <>
            <div className="mind-map-canvas" ref={mindmapCanvasRef} >
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="mind-map-video"
                >
                    <source src={mindMapVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="mind-map-gradient-overlay"></div>
            </div>
            <div className="mind-map-container" ref={mindmapSectionRef}>
                <p className='landing-page-mind-map-text' ref={mindmapTextRef}>
                    {["Visualise, for the first time, the contents of your mind. ", "As you learn, new stars appear and connect. ", "As you grow, it grows. ", "An immersive, living mirror to your mind, completely unique to you. ", "Mind Map is currently the most compelling way of tracking your personal development."].map((text, index) => (
                        <span
                            key={index}
                            ref={el => spansRefs.current[index] = el} // Set ref for each span
                            // style={index === 0 ? { display: 'block', fontSize: '100px' } : {}} // Only apply display block for the first span
                        >
                            {text}
                        </span>
                    ))}
                </p>
            </div>
        </>
    );
}

export default MindMapLP;