// App.js
import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/helpers/AuthProvider';
import { SidebarProvider } from './components/Sidebar/SidebarContext';
import LandingPage5 from './components/LandingPage5/LandingPage5';
import StudioLayout from './components/StudioLayout/StudioLayout';
import Welcome from './components/Welcome/Welcome';
import Chat from './components/Chat/Chat';
import Classroom from './components/Classroom/Classroom';
import InitialClassroomPage from './components/Classroom/InitialClassroomPage';
import Stats from './components/Stats/Stats';
import CreateQuiz from './components/Classroom/CreateQuiz';
import QuizPage from './components/Classroom/QuizPage';
import ResultsPage from './components/Classroom/ResultsPage'; // Import the ResultsPage component

function App() {
  useEffect(() => {
    console.log('App component mounted.');
    return () => {
      console.log('App component unmounted.');
    };
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage5 />} />
          <Route path="/studio" element={
            <ProtectedRoute>
              <SidebarProvider>
                <StudioLayout />
              </SidebarProvider>
            </ProtectedRoute>
          }>
            <Route index element={<Welcome />} />
            <Route path="chat" element={<Chat />} />
            <Route path="classroom" element={<Classroom />}>
              {/* Default route for the classroom */}
              <Route index element={<InitialClassroomPage />} />
              {/* Route for quiz creation with a unique ID */}
              <Route path="create/:id" element={<CreateQuiz />} />
              {/* Route for the quiz page */}
              <Route path="quiz/:id" element={<QuizPage />} />
              {/* Route for the results page */}
              <Route path="quiz/results/:id" element={<ResultsPage />} />
            </Route>
            <Route path="stats" element={<Stats />} />
          </Route>
          <Route path="*" element={<div>Page Not Found or Misconfigured Route</div>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();
  if (loading || user === undefined) {
    return <div>Loading...</div>;
  }
  if (!user) {
    return <Navigate to="/" replace />;
  }
  return children;
}

export default App;