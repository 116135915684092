import React, { useState, useEffect } from 'react';
import './LoadingScreen.css';
import logo from '../../assets/images/logo-inspiration-compass-image-2-removebg-preview.png';

function LoadingScreen({ quizTitle, message, complete, difficulty }) {
  const [progress, setProgress] = useState(0);

  // Define the styles for each difficulty.
  const difficultyStyles = {
    Novice: {
      background: 'linear-gradient(135deg, #d6c6ff, #b19cd9)',
    },
    Apprentice: {
      background: 'linear-gradient(135deg, #cce5ff, #99ccff)',
    },
    Adept: {
      background: 'linear-gradient(135deg, #b7f0e0, #91d8cc)',
    },
    Expert: {
      background: 'linear-gradient(135deg, #ffbdbd, #ff9999)',
    },
    Master: {
      background: 'linear-gradient(135deg, #fff4cc, #ffe066)',
    },
  };

  // Use the provided difficulty to choose the style, or default if not matched.
  const styleForDifficulty = difficultyStyles[difficulty] || {
    background: 'linear-gradient(135deg, #4f4e55, #b5b5c6)',
    border: '1px solid #b5b5c6',
  };

  useEffect(() => {
    // When complete is true, jump to 100%.
    if (complete) {
      setProgress(100);
      return;
    }

    // Otherwise, simulate progress.
    const interval = setInterval(() => {
      setProgress((prev) => {
        const increment = Math.random() * 5;
        const next = Math.min(prev + increment, 95);
        return next;
      });
    }, 1000); // update every 1000ms

    return () => clearInterval(interval);
  }, [complete]);

  return (
    <div className="loading-screen">
      <img src={logo} alt="Logo" className="loading-screen-logo" draggable="false" />
      <p className="paradigm-title">Paradigm</p>
      <p className="quiz-title">{quizTitle}</p>
      <div
        className="loading-bar-container"
        style={{ border: styleForDifficulty.border }}
      >
        <div
          className="loading-bar"
          style={{
            '--progress-width': `${progress}%`,
            '--loading-background': styleForDifficulty.background,
          }}
        ></div>
      </div>
      {message && <p className="loading-message">{message}</p>}
    </div>
  );
}

export default LoadingScreen;
