import axios from 'axios';

class QuizService {
  constructor({ onMessage, classroomId, token }) {
    this.onMessage = onMessage;
    this.classroomId = classroomId;
    this.token = token;
    this.ws = null;
    this.isOpen = false;
    this.quizReceived = false;

    console.log("📌 Initializing QuizService...");
    console.log("📌 classroomId:", classroomId);
    console.log("📌 JWT Token:", token);

    if (!token) {
      console.error("❌ Error: JWT token is missing in QuizService!");
    }
    if (!classroomId) {
      console.error("❌ Error: classroomId is missing in QuizService!");
    }
  }

  connect() {
    return new Promise((resolve, reject) => {
      if (!this.classroomId || !this.token) {
        console.error("❌ Cannot connect WebSocket: Missing `classroomId` or `token`!");
        return;
      }

      this.ws = new WebSocket('wss://dev.chromecopilot.ai/ws/generate_quiz/');

      this.ws.onopen = () => {
        console.log('✅ WebSocket connected to QuizService');
        this.isOpen = true;
        resolve();
      };

      this.ws.onmessage = async (event) => {
        const data = JSON.parse(event.data);
        console.log('📨 WebSocket received message in QuizService:', data);

        if (data.type === 'quiz_data') {
          this.quizReceived = true;
          console.log('✅ Quiz data received. WebSocket will close after processing.');

          try {
            let parsedQuiz = typeof data.quiz === "string" ? JSON.parse(data.quiz) : data.quiz;

            if (parsedQuiz.quiz && Array.isArray(parsedQuiz.quiz)) {
              console.log("📥 Parsed quiz data:", parsedQuiz.quiz);

              // ✅ Save the quiz to the backend in the `questions` field
              await this.saveQuizToBackend(parsedQuiz.quiz);

              // ✅ Send parsed quiz to `QuizPage.js`
              this.onMessage(parsedQuiz.quiz);
            } else {
              console.error("❌ Quiz data structure is incorrect:", parsedQuiz);
            }
          } catch (error) {
            console.error("❌ Error parsing quiz data:", error);
          }
        }
      };

      this.ws.onclose = (event) => {
        if (this.quizReceived) {
          console.log('✅ WebSocket disconnected after receiving quiz data.');
        } else {
          console.warn(`⚠️ WebSocket disconnected prematurely with code ${event.code}`);
        }
        this.isOpen = false;
      };

      this.ws.onerror = (error) => {
        console.error('❌ WebSocket error in QuizService:', error);
        reject(error);
      };
    });
  }

  async saveQuizToBackend(quizData) {
    if (!this.token || !this.classroomId) {
      console.error("❌ Cannot save quiz: Missing token or classroomId!");
      return;
    }

    try {
      console.log("💾 Saving quiz data to backend...");
      const response = await axios.patch(
        `/api/quizzes/?classroom_id=${this.classroomId}`,
        { questions: quizData }, // ✅ Save to `questions` field
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log("✅ Quiz data successfully saved to backend:", response.data);
    } catch (error) {
      console.error("❌ Error saving quiz data to backend:", error.response?.data || error.message);
    }
  }

  async sendQuizRequest(messages) {
    if (!this.isOpen || this.ws.readyState !== WebSocket.OPEN) {
      console.warn('⚠️ WebSocket is not open. Attempting to reconnect...');
      await this.connect();
    }

    this.quizReceived = false;

    const payload = { token: this.token, type: 'generate_quiz', messages };
    console.log('🚀 Sending quiz generation request:', payload);
    this.ws.send(JSON.stringify(payload));
  }

  close() {
    if (this.ws && this.quizReceived) {
      console.log('🛑 Closing WebSocket connection in QuizService after receiving quiz data');
      this.ws.close();
    }
  }
}

export default QuizService;
