// QuizPage.js
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../helpers/AuthProvider';
import { gsap } from 'gsap';
import MultipleChoiceQuestion from './QuizTypes/MultipleChoiceQuestion';
import TrueFalseQuestion from './QuizTypes/TrueFalseQuestion';
import FillInTheBlankQuestion from './QuizTypes/FillInTheBlankQuestion';
import ShortAnswerQuestion from './QuizTypes/ShortAnswerQuestion';
import QuizService from './QuizService';
import CorrectAnswer from './QuizTypes/CorrectAnswer';
import IncorrectAnswer from './QuizTypes/IncorrectAnswer';
import './QuizPage.css';

function QuizPage() {
  const { id: classroomId } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();

  // Store full quiz details from the backend (including questions, timer fields, etc.)
  const [quizDetails, setQuizDetails] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizTitle, setQuizTitle] = useState('');
  const [quizDifficulty, setQuizDifficulty] = useState('');
  const [error, setError] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [feedbackComponent, setFeedbackComponent] = useState(null);
  // State to ensure we update the start_time only once.
  const [startTimeSet, setStartTimeSet] = useState(false);

  const containerRef = useRef(null);
  const quizService = useRef(null);

  // Mapping for progress circle colors (if needed)
  const difficultyColors = {
    Novice: '#b19cd9',
    Apprentice: '#99ccff',
    Adept: '#91d8cc',
    Expert: '#ff9999',
    Master: '#ffe066'
  };

  console.log("🔄 Rendering QuizPage...");
  console.log("📌 classroomId:", classroomId);
  console.log("📌 JWT Token:", token);
  console.log("📊 Current quizDetails:", quizDetails);

  // Fetch quiz details and the full quiz data from the backend.
  useEffect(() => {
    if (!classroomId || !token) {
      console.error("❌ Missing classroomId or token! Aborting quiz fetch.");
      setError("Missing classroom or authentication token.");
      return;
    }

    // Fetch quiz details (such as title and difficulty)
    const fetchQuizDetails = async () => {
      try {
        const response = await axios.get(`/api/quizzes/?classroom_id=${classroomId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data) {
          setQuizTitle(response.data.title || 'Untitled Quiz');
          setQuizDifficulty(response.data.selected_difficulty || 'Unknown');
        }
      } catch (error) {
        console.error("❌ Error fetching quiz details:", error.response?.data || error.message);
        setError('Failed to load quiz details.');
      }
    };

    // Fetch the full quiz (including questions and timer fields) from the backend.
    const fetchSavedQuiz = async () => {
      try {
        const response = await axios.get(`/api/quizzes/?classroom_id=${classroomId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.questions) {
          // Store the entire quiz object
          setQuizDetails(response.data);
          setIsDataLoaded(true);
        } else {
          console.log("⚠️ No saved quiz found. Generating new quiz...");
          startNewQuiz();
        }
      } catch (error) {
        console.error("❌ Error fetching saved quiz:", error.response?.data || error.message);
        startNewQuiz();
      }
    };

    // If no saved quiz exists, start a new one via WebSocket.
    const startNewQuiz = () => {
      quizService.current = new QuizService({
        classroomId,
        token,
        onMessage: (data) => {
          console.log("📨 WebSocket received quiz data:", data);
          if (data.quiz) {
            setQuizDetails(data.quiz);
            setIsDataLoaded(true);
          } else {
            console.error("⚠️ Received WebSocket response, but missing quiz data.");
          }
        },
      });
      quizService.current.connect();
    };

    fetchQuizDetails().then(fetchSavedQuiz);

    return () => {
      console.log("🛑 Cleaning up: Closing WebSocket...");
      quizService.current?.close();
    };
  }, [classroomId, token]);

  // Update the quiz start_time when the full quiz data has loaded (only once)
  useEffect(() => {
    if (isDataLoaded && !startTimeSet) {
      axios.patch(
        `/api/quizzes/?classroom_id=${classroomId}`,
        { start_time: new Date().toISOString() },
        { headers: { Authorization: `Bearer ${token}` } }
      )
        .then(response => {
          console.log("✅ Quiz start time updated:", response.data.start_time);
          setStartTimeSet(true);
        })
        .catch(error => {
          console.error("Error updating start time:", error.response?.data || error.message);
        });
    }
  }, [isDataLoaded, startTimeSet, classroomId, token]);

  // Animate container when data is loaded
  useLayoutEffect(() => {
    if (isDataLoaded && containerRef.current) {
      console.log("🎬 All data loaded! Running GSAP animation...");
      gsap.fromTo(
        containerRef.current,
        { zIndex: -1, scale: 0.5, opacity: 0 },
        { zIndex: 1, scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
      );
    }
  }, [isDataLoaded]);

  // Animate progress circles when the current question index changes.
  useEffect(() => {
    const circles = gsap.utils.toArray('.progress-circle');
    circles.forEach((circle, idx) => {
      gsap.to(circle, { scale: idx === currentQuestionIndex ? 1.3 : 1, duration: 0.2, ease: 'power2.out' });
    });
  }, [currentQuestionIndex]);

  if (!isDataLoaded) {
    return <p>Loading quiz...</p>;
  }

  const questions = quizDetails.questions;
  const currentQuestion = questions[currentQuestionIndex];

  // Handler for answer submission (passed down to each question component)
  const handleAnswerSubmit = (isCorrect, explanation) => {
    console.log(isCorrect ? "✅ Correct answer!" : "❌ Incorrect answer!", explanation);
    const isLastQuestion = currentQuestionIndex === questions.length - 1;
    if (isCorrect) {
      setFeedbackComponent(
        <CorrectAnswer
          explanation={explanation}
          onClose={isLastQuestion ? handleShowResults : handleNext}
          isLastQuestion={isLastQuestion}
        />
      );
    } else {
      setFeedbackComponent(
        <IncorrectAnswer
          explanation={explanation}
          onClose={isLastQuestion ? handleShowResults : handleNext}
          isLastQuestion={isLastQuestion}
        />
      );
    }
  };

  // Render the appropriate question component based on its type; pass down isLastQuestion.
  const renderQuestionComponent = () => {
    const isLastQuestion = currentQuestionIndex === questions.length - 1;
    switch (currentQuestion.type) {
      case 'Multiple Choice':
        return <MultipleChoiceQuestion
          key={currentQuestion.id}
          questionData={currentQuestion}
          difficulty={quizDifficulty}
          onAnswerSubmit={handleAnswerSubmit}
          isLastQuestion={isLastQuestion}
        />;
      case 'True or False':
        return <TrueFalseQuestion
          key={currentQuestion.id}
          questionData={currentQuestion}
          difficulty={quizDifficulty}
          onAnswerSubmit={handleAnswerSubmit}
          isLastQuestion={isLastQuestion}
        />;
      case 'Fill in the Blank':
        return <FillInTheBlankQuestion
          key={currentQuestion.id}
          questionData={currentQuestion}
          difficulty={quizDifficulty}
          onAnswerSubmit={handleAnswerSubmit}
          isLastQuestion={isLastQuestion}
        />;
      case 'Short Answer':
        return <ShortAnswerQuestion
          key={currentQuestion.id}
          questionData={currentQuestion}
          difficulty={quizDifficulty}
          onAnswerSubmit={handleAnswerSubmit}
          isLastQuestion={isLastQuestion}
        />;
      default:
        return <p>❌ Unknown question type</p>;
    }
  };

  // Update the current question number on the backend.
  const updateQuizProgress = async (questionNumber) => {
    try {
      const response = await axios.patch(
        `/api/quizzes/?classroom_id=${classroomId}`,
        {
          current_question_number: questionNumber,
          has_started: true,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("Current question number updated to:", response.data.current_question_number);
    } catch (error) {
      console.error("Error updating quiz progress:", error.response?.data || error.message);
    }
  };

  // Advance to the next question.
  const handleNext = () => {
    setFeedbackComponent(null);
    if (currentQuestionIndex < questions.length - 1) {
      const newIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(newIndex);
      updateQuizProgress(newIndex + 1);
    }
  };

  // When the last question is answered, navigate to the results route.
  const handleShowResults = () => {
    // Navigate to the full results page route, which should be defined as
    // "/studio/classroom/quiz/results/:id" in your App.js routing.
    navigate(`/studio/classroom/quiz/results/${classroomId}`);
  };

  return (
    <div ref={containerRef} className="quiz-page">
      <div className="quiz-header">
        <h1>{quizTitle}</h1>
      </div>
      <div className="quiz-question">
        {renderQuestionComponent()}
        {feedbackComponent}
      </div>
      <div className="question-progress">
        {questions.map((_, index) => (
          <div
            key={index}
            className={`progress-circle ${index === currentQuestionIndex ? 'active' : ''}`}
            style={index === currentQuestionIndex ? { backgroundColor: difficultyColors[quizDifficulty] } : {}}
          >
            {index + 1}
          </div>
        ))}
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default QuizPage;
